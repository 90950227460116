import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function XmasBackground({ children }) {
  return (
    <>
      <div className="relative h-full w-full min-h-screen">
        <XmasHeader />
        <WhitePaperBackground />
        <XmasTopLeftMotive />
        <XmasTopRightMotive />
        <XmasBottomLeftMotive />
        <XmasBottomRightMotive />
        {children}
      </div>
    </>
  );
}

function XmasHeader() {
  return (
    <header className="flex justify-center py-0 sm:py-0 px-5 font-normal landscape:h-[20vh] landscape:lg:h-[15vh] h-[15vh] md:h-[10vh] max-h-32">
      <StaticImage
        src="../img/imported_with_StaticImage/adv23-logo.png"
        loading="eager"
        className=""
        objectFit="contain"
        alt=""
        placeholder="none"
      />
    </header>
  );
}

function WhitePaperBackground() {
  return (
    <>
      <div className="absolute inset-0 w-full h-full z-[-100]">
        <StaticImage
          src="../img/imported_with_StaticImage/ADV22/HintergrundPapier.jpg"
          loading="eager"
          className="h-full"
          objectFit="cover"
          alt=""
          placeholder="none"
          quality={50}
        />
      </div>
    </>
  );
}

function XmasTopLeftMotive() {
  return (
    <div className="hidden sm:block absolute top-0 left-0 z-[-10] h-auto w-1/6 max-w-xs">
      <StaticImage
        src="../img/imported_with_StaticImage/ADV22/Elemente_linksoben.png"
        loading="eager"
        className=""
        objectFit="contain"
        alt=""
        placeholder="none"
        width={400}
        height={400}
      />
    </div>
  );
}

function XmasTopRightMotive() {
  return (
    <div className="hidden sm:block absolute top-0 right-0 z-[-10] h-auto w-1/4 max-w-xs">
      <StaticImage
        src="../img/imported_with_StaticImage/ADV22/Elemente_rechtsoben.png"
        loading="eager"
        className=""
        objectFit="contain"
        alt=""
        placeholder="none"
        width={400}
        height={400}
      />
    </div>
  );
}

function XmasBottomLeftMotive() {
  return (
    <div className="absolute bottom-0 left-0 z-[-10] h-auto w-1/3">
      <StaticImage
        src="../img/imported_with_StaticImage/ADV22/Elemente_linksunten.png"
        loading="eager"
        className="h-full"
        objectFit="contain"
        alt=""
        placeholder="none"
        width={400}
        height={400}
      />
    </div>
  );
}

function XmasBottomRightMotive() {
  return (
    <div className="absolute bottom-0 right-0 flex justify-end z-[-10] h-auto w-1/3 max-w-xs">
      <StaticImage
        src="../img/imported_with_StaticImage/ADV22/Elemente_rechtsunten.png"
        loading="eager"
        className="h-full"
        objectFit="contain"
        alt=""
        placeholder="none"
        width={400}
        height={400}
      />
    </div>
  );
}
