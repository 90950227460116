import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Section from "../components/Section";
import Video from "../components/Video";
import FunnelForm from "../components/FunnelForm";
import XmasBackground from "../components/XmasBackground";
import { StaticImage } from "gatsby-plugin-image";

const VideoOptInPageTemplate = ({ data }) => {
  data.heroObject.video.format = "Querformat";

  return (
    <Layout isWithHeader={false} seoObject={data.seoObject}>
      <XmasBackground>
        <Section
          classNameInnerContainer="flex flex-col space-y-5"
          className="pt-0 sm:pt-4"
        >
          <div className="text-center">
            <RenderMarkdown
              markdownContent={data.heroObject.title}
              isMarkdown={false}
              // class for spacing between lines: leading-none, leading-tight, leading-snug, leading-normal
              className="font-medium text-4xl sm:text-6xl text-[#4D6039] leading-tight mt-0"
              whitespace="normal"
            />
            <RenderMarkdown
              markdownContent={data.heroObject.subtitle}
              isMarkdown={false}
              className="shortcut text-2xl sm:text-4xl text-brand-black mt-2 sm:mt-5"
              whitespace="normal"
            />
          </div>
          <Video videoObject={data.heroObject.video} loading="eager" />
          <div className="mx-auto w-full max-w-xl lg:max-w-5xl">
            <FunnelForm
              classNameForm=""
              classNameButton="w-full sm:w-auto mx-auto !bg-[#fc865b]"
              data={data.signUpObject}
            />
          </div>
          <StaticImage
            className="mx-auto md:w-2/5"
            alt="Bild aller beim Adventskalender teilnehmenden Coaches"
            src="../img/imported_with_StaticImage/adv23-experten-title.png"
          />
          <div className="mx-auto md:w-4/5 flex flex-wrap justify-center markdown">
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Andreas Schwarz.png"
              />
              <h3 className="text-center font-medium">Andreas Schwarz</h3>
              <p className="text-center">
                Spiritualität, Spiegel-Bestseller-Autor & Gründer von Highermind
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Bahar Yilmaz.png"
              />
              <h3 className="text-center font-medium">Bahar Yilmaz</h3>
              <p className="text-center">
                Empower Yourself & Spiegel-Bestseller-Autorin
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Christian Bischoff.png"
              />
              <h3 className="text-center font-medium">Christian Bischoff</h3>
              <p className="text-center">
                Spiegel-Bestseller-Autor, Persönlichkeits- & Mentaltrainer
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Curse.png"
              />
              <h3 className="text-center font-medium">Curse</h3>
              <p className="text-center">Gründer des Bad Meditators Clubs</p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Dana Schwandt.png"
              />
              <h3 className="text-center font-medium">Dana Schwandt</h3>
              <p className="text-center">
                Transformations-Trainerin, Spiegel-Bestseller-Autorin &
                Gründerin von Ichgold
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Dr. Wolf-Dieter Nagl.png"
              />
              <h3 className="text-center font-medium">
                Dr. med. Wolf-Dieter Nagl
              </h3>
              <p className="text-center">
                Hypnosetherapeut, Allgemeinmediziner & Meditationstrainer
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Jeffrey Kastenmüller.png"
              />
              <h3 className="text-center font-medium">Jeffrey Kastenmüller</h3>
              <p className="text-center">
                Persönlichkeits
                <wbr />
                entwickler, Spiegel-Bestseller-Autor & Gründer von Heartwall
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Mareike Awe.png"
              />
              <h3 className="text-center font-medium">Dr. med. Mareike Awe</h3>
              <p className="text-center">
                Ärztin, Ernärungsexpertin & Gründerin von intueat
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Maxim Mankevich.png"
              />
              <h3 className="text-center font-medium">Maxim Mankevich</h3>
              <p className="text-center">
                Experte für Genialität & Spiegel-Bestseller-Autor
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Peter Beer.png"
              />
              <h3 className="text-center font-medium">Peter Beer</h3>
              <p className="text-center">
                Meditationstrainer, Spiegel-Bestseller-Autor & Gründer der
                Achtsamkeits
                <wbr />
                akademie
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Rüdiger Dahlke.png"
              />
              <h3 className="text-center font-medium">Rüdiger Dahlke</h3>
              <p className="text-center">
                Ganzheitliche Heilung & Gesundheitscoach
              </p>
            </div>
            <div className="flex flex-col items-center w-1/2 lg:w-1/3 p-2 mt-4 overflow-hidden">
              <StaticImage
                className="mx-auto w-4/5 sm:w-1/2 mb-2 rounded-full"
                alt="Peter Beer"
                src="../img/imported_with_StaticImage/expertenteam-adv-23/Sarah Desai.png"
              />
              <h3 className="text-center font-medium">Sarah Desai</h3>
              <p className="text-center">
                Spiritualitätscoachin & Spiegel-Bestseller-Autorin
              </p>
            </div>
          </div>
          <div className="mx-auto w-full max-w-xl lg:max-w-5xl">
            <FunnelForm
              classNameForm=""
              classNameButton="w-full sm:w-auto mx-auto !bg-[#fc865b]"
              data={data.signUpObject}
            />
          </div>
        </Section>
      </XmasBackground>
    </Layout>
  );
};

const VideoOptInPage = ({ data }) => {
  return <VideoOptInPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default VideoOptInPage;

export const pageQuery = graphql`
  query VideoOptInPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
        heroObject {
          ctaObject {
            linkText
            linkUrl
          }
          title
          subtitle
          video {
            cloudflareId
            thumbnail
          }
        }
        signUpObject {
          title
          subtitle
          cta
          ctaSubtitle
          funnelId
          href
        }
      }
    }
  }
`;
